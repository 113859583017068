import { useEffect } from 'react';
import { Outlet } from 'react-router';
import { Box } from '@mui/material';
import { useNotificationsGetCounters, useUsersGetPersonalInfo } from 'api/endpoints/grapp-api-generated';
import { EUsersRoles } from 'api/model';
import { EEventType } from 'enums/socket-events';
import { LS_SIDEBAR } from 'gr-constants/localstorage-consts';
import { useFetchQuery } from 'hooks/use-fetch-query';
import { useLocalStorage } from 'hooks/use-local-storage';
import { useSocket } from 'hooks/use-sockets';
import { useAuthStore } from 'store/auth';
import { useGlobalStore } from 'store/global';
import { useRegisterStore } from 'store/register';

import ConfirmationModal from 'components/dialog/partials/confirmation-modal';
import { DrawerHeader } from 'components/layouts/root-layout/styles';

import Header from './partials/header';
import Sidebar from './partials/sidebar';

const RootLayout = () => {
	const { onEvent, offEvent } = useSocket();
	const { setCounters, setEventType } = useRegisterStore();
	const { confirmationModalParams, setConfirmationModalParams } = useGlobalStore();
	const { user, setAuthData } = useAuthStore();
	const [open, setOpen] = useLocalStorage(LS_SIDEBAR, true);

	const personalDataQuery = useUsersGetPersonalInfo();

	const { refetch } = useFetchQuery({
		queryResult: useNotificationsGetCounters({
			query: {
				enabled: user?.role !== EUsersRoles.SuperAdmin && user?.role !== EUsersRoles.SuperUser,
			},
		}),
		onSuccess: (data) => setCounters(data),
	});

	useFetchQuery({
		queryResult: personalDataQuery,
		onSuccess: (data) => setAuthData(data),
	});

	useEffect(() => {
		onEvent('entityEvent', (data) => {
			const response = data as { eventType: EEventType };
			setEventType(response.eventType);
			refetch();
		});

		return () => {
			offEvent('entityEvent');
		};
	}, [onEvent, offEvent]);

	return (
		<>
			<Box sx={{ display: 'flex' }}>
				<Header open={open} toggleDrawer={setOpen} />
				<Sidebar open={open} />
				<Box component="main" sx={{ flexGrow: 1, overflow: 'auto' }}>
					<DrawerHeader />
					<Box sx={{ p: 5, minHeight: `calc(100vh - 64px)` }}>
						<Outlet />
					</Box>
				</Box>
			</Box>
			{confirmationModalParams && (
				<ConfirmationModal
					open={!!confirmationModalParams.open}
					title={confirmationModalParams.title}
					description={confirmationModalParams.description}
					handleClose={() => setConfirmationModalParams(null)}
					handleSubmit={() => {
						confirmationModalParams?.handleSubmit && confirmationModalParams.handleSubmit();
					}}
					isPending={confirmationModalParams.isPending}
				/>
			)}
		</>
	);
};

export default RootLayout;

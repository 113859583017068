import { FC } from 'react';
import { DialogActions as MuiDialogActions } from '@mui/material';

import Button from 'components/button/Button';

type DialogPropsType = {
	cancelLabel?: string;
	cancelAction?: () => void;
	applyLabel?: string;
	applyAction?: () => void;
	isLoading?: boolean;
	applyType?: 'reset' | 'button' | 'submit' | undefined;
};

const DialogActions: FC<DialogPropsType> = ({ cancelLabel, cancelAction, applyLabel, applyAction, isLoading, applyType = 'submit' }) => {
	return applyLabel || cancelLabel ? (
		<MuiDialogActions sx={{ paddingLeft: '0!important', paddingRight: '0!important' }}>
			{cancelLabel && (
				<Button variant="outlined" onClick={cancelAction}>
					{cancelLabel}
				</Button>
			)}
			{applyLabel && (
				<Button type={applyType} onClick={applyAction} loading={isLoading}>
					{applyLabel}
				</Button>
			)}
		</MuiDialogActions>
	) : null;
};

export default DialogActions;

import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import { theme } from 'shared/theme';

import Dialog from 'components/dialog';
import DialogActions from 'components/dialog/partials/dialog-actions/DialogActions';
import { CustomDialogType } from 'components/dialog/type-helpers';

type ConfirmationModalPropsType = CustomDialogType<unknown> & {
	title: string;
	description: string | React.ReactNode;
	isPending?: boolean;
};
// TODO remove this component everywhere instead of RootLayout.tsx
const ConfirmationModal: FC<ConfirmationModalPropsType> = ({ open, handleClose, title, description, isPending, handleSubmit }) => {
	const { formatMessage } = useIntl();

	return (
		<Dialog title={title} open={open} handleClose={handleClose}>
			<Box sx={{ maxWidth: '400px' }}>
				<Typography variant="body1" sx={{ margin: `${theme.spacing(3)} 0`, fontWeight: '500' }}>
					{description}
				</Typography>
				<DialogActions
					cancelLabel={formatMessage({ defaultMessage: 'Cancel', id: '47FYwb' })}
					cancelAction={handleClose}
					applyLabel={formatMessage({ defaultMessage: 'Confirm', id: 'N2IrpM' })}
					applyAction={handleSubmit}
					isLoading={isPending}
				/>
			</Box>
		</Dialog>
	);
};

export default ConfirmationModal;

import { EAbsenceType, ELeaveRequestType, EShiftType, NotificationsGetCountersResDto, WorkDaysGetListResDto } from 'api/model';
import { EEventType } from 'enums/socket-events';
import { create } from 'zustand';

import { SelectOptionType } from 'components/inputs/type-helpers';

type SelectedOptionsType = {
	userId?: SelectOptionType | null;
	departmentId?: string;
	shiftType?: EShiftType;
	leaveRequestType?: ELeaveRequestType;
	absenceType?: EAbsenceType;
};

type RegisterStoreType = {
	calendarDate: Date;
	isFilterDrawerOpen: boolean;
	selectedFilters: SelectedOptionsType;
	eventType: EEventType | null;
	notificationCounters: NotificationsGetCountersResDto | null;
	workDay: WorkDaysGetListResDto | null;
	workDayDate: Date | null;
};

type RegisterActions = {
	setCalendarDate: (value: Date) => void;
	toggleFilterDrawer: (open: boolean) => void;
	setSelectedFilters: (selectedFilters: SelectedOptionsType) => void;
	setEventType: (eventType: EEventType | null) => void;
	setCounters: (counters: NotificationsGetCountersResDto) => void;
	setWorkDay: (counters: WorkDaysGetListResDto | null) => void;
	setWorkDayDate: (date: Date | null) => void;
	reset: () => void;
};

export const initialRegisterState: RegisterStoreType = {
	calendarDate: new Date(),
	isFilterDrawerOpen: false,
	selectedFilters: {
		userId: undefined,
		departmentId: undefined,
		shiftType: undefined,
		leaveRequestType: undefined,
		absenceType: undefined,
	},
	eventType: null,
	notificationCounters: null,
	workDay: null,
	workDayDate: null,
};

const registerStore = create<RegisterStoreType & RegisterActions>()((set) => ({
	...initialRegisterState,
	setCalendarDate: (calendarDate) => set(() => ({ calendarDate })),
	toggleFilterDrawer: (isFilterDrawerOpen) => set(() => ({ isFilterDrawerOpen })),
	setSelectedFilters: (selectedFilters) => set(() => ({ selectedFilters })),
	setEventType: (eventType) => set(() => ({ eventType })),
	setCounters: (notificationCounters) => set(() => ({ notificationCounters })),
	setWorkDay: (workDay) => set(() => ({ workDay })),
	setWorkDayDate: (workDayDate) => set(() => ({ workDayDate })),
	reset: () => set(() => ({ ...initialRegisterState })),
}));

export const useRegisterStore = () => registerStore((state) => state);

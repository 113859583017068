import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { Routes } from 'routes/consts';

import AuthorisedLayout from 'components/layouts/authorised-layout';
import RootLayout from 'components/layouts/root-layout';
import UnauthorisedLayout from 'components/layouts/unauthorised-layout';

const Login = lazy(() => import('pages/auth/login'));
const ForgotPassword = lazy(() => import('pages/auth/forgot-pasword'));
const CreatePassword = lazy(() => import('pages/auth/create-password'));
const AdminUsers = lazy(() => import('pages/admin/users'));
const AdminSubscriptions = lazy(() => import('pages/admin/subscriptions'));
const AdminClients = lazy(() => import('pages/admin/clients'));
const AdminClientDetails = lazy(() => import('pages/admin/clients/details'));
const AdminClientDeviceDetails = lazy(() => import('pages/admin/clients/device-details'));
const ProfileSettings = lazy(() => import('pages/profile-settings'));
const Users = lazy(() => import('pages/users'));
const AttendanceRegister = lazy(() => import('pages/attendance-register'));
const ExitNotices = lazy(() => import('pages/exit-notices'));
const ExitUserLocation = lazy(() => import('pages/exit-user-location'));
const NotAccess = lazy(() => import('pages/not-access'));
const Devices = lazy(() => import('pages/devices'));
const DeviceById = lazy(() => import('pages/devices/device-by-id'));

export const RoutesConfig: RouteObject[] = [
	{
		path: Routes.root,
		element: (
			<AuthorisedLayout>
				<RootLayout />
			</AuthorisedLayout>
		),
		children: [
			{
				path: Routes.users,
				element: <Users />,
			},
			{
				path: Routes.devices,
				element: <Devices />,
			},
			{
				path: Routes.device_by_id,
				element: <DeviceById />,
			},
			{
				path: Routes.admin_users,
				element: <AdminUsers />,
			},
			{
				path: Routes.admin_subscriptions,
				element: <AdminSubscriptions />,
			},
			{
				path: Routes.admin_clients,
				element: <AdminClients />,
			},
			{
				path: Routes.admin_client_details,
				element: <AdminClientDetails />,
			},
			{
				path: Routes.admin_client_details_device_details,
				element: <AdminClientDeviceDetails />,
			},
			{
				path: Routes.profile_settings,
				element: <ProfileSettings />,
			},
			{
				path: Routes.register,
				element: <AttendanceRegister />,
			},
			{
				path: Routes.exit_notices,
				element: <ExitNotices />,
			},
			{
				path: Routes.exit_user_location,
				element: <ExitUserLocation />,
			},
		],
	},
	{
		path: Routes.root,
		element: (
			<UnauthorisedLayout>
				<Outlet />
			</UnauthorisedLayout>
		),
		children: [
			{
				path: Routes.login,
				element: <Login />,
			},
			{
				path: Routes.forgotPassword,
				element: <ForgotPassword />,
			},
			{
				path: Routes.createPassword,
				element: <CreatePassword />,
			},
		],
	},
	{
		path: Routes.root,
		element: (
			<AuthorisedLayout>
				<Outlet />
			</AuthorisedLayout>
		),
		children: [
			{
				path: Routes.not_access,
				element: <NotAccess />,
			},
		],
	},
	{
		path: '*',
		element: <>Error</>,
	},
];

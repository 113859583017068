import { EUsersRoles } from 'api/model';

export const Routes = {
	root: '/',
	login: '/login',
	forgotPassword: '/forgot-password',
	createPassword: '/create-password',
	users: '/users',
	register: '/register',
	devices: '/devices',
	device_by_id: '/devices/:deviceId',
	exit_notices: '/exit-notices',
	exit_user_location: '/exit-notices/:userId',
	admin_users: '/gr-users',
	admin_clients: '/gr-clients',
	admin_client_details: '/gr-clients/:companyId',
	admin_client_details_device_details: '/gr-clients/:companyId/:deviceId',
	admin_subscriptions: '/gr-subscriptions',
	profile_settings: '/profile-settings',
	not_access: '/not-access',
};

export const queryVar = {
	tab: 'tab',
	view: 'view',
	id: 'id',
} as const;

export const queryValues = {
	// values for tab variable attendance-register section
	calendar: 'calendar',
	requests: 'requests',
	absences: 'absences',
	pay_slips: 'pay-slips',
	planning: 'planning',
	analyses: 'analyses',
	// values for tab variable admin user details section
	client_info: 'client-info',
	users_list: 'users-list',
	devices: 'devices',
	// values for tab variable admin user details section
	exit_requests: 'exit-requests',
	exit_tracking: 'tracking',
	exit_settings: 'exit-settings',
	// values for view variable
	stamping: 'stamping',
	in_company: 'users-in-company',
} as const;

export const RoleBasedRoutes: Record<
	string,
	{
		path: string;
		access: Array<(typeof EUsersRoles)[keyof typeof EUsersRoles]>;
	}
> = {
	root: {
		path: Routes.root,
		access: [],
	},
	// Auth routes
	login: {
		path: Routes.login,
		access: [],
	},
	forgotPassword: {
		path: Routes.forgotPassword,
		access: [],
	},
	createPassword: {
		path: Routes.createPassword,
		access: [],
	},
	// Client area routes
	users: {
		path: Routes.users,
		access: [EUsersRoles.Admin],
	},
	devices: {
		path: Routes.devices,
		access: [EUsersRoles.Admin, EUsersRoles.Responsible],
	},
	device_by_id: {
		path: Routes.device_by_id,
		access: [EUsersRoles.Admin, EUsersRoles.Responsible],
	},
	register: {
		path: Routes.register,
		access: [EUsersRoles.Admin, EUsersRoles.Responsible, EUsersRoles.BackOffice],
	},
	exit_notices: {
		path: Routes.exit_notices,
		access: [EUsersRoles.Admin, EUsersRoles.Responsible, EUsersRoles.BackOffice],
	},
	exit_user_location: {
		path: Routes.exit_user_location,
		access: [EUsersRoles.Admin, EUsersRoles.Responsible, EUsersRoles.BackOffice],
	},
	// Admin routes
	admin_users: {
		path: Routes.admin_users,
		access: [EUsersRoles.SuperAdmin],
	},
	admin_clients: {
		path: Routes.admin_clients,
		access: [EUsersRoles.SuperAdmin, EUsersRoles.SuperUser],
	},
	admin_clients_details: {
		path: Routes.admin_client_details,
		access: [EUsersRoles.SuperAdmin, EUsersRoles.SuperUser],
	},
	admin_client_details_device_details: {
		path: Routes.admin_client_details,
		access: [EUsersRoles.SuperAdmin, EUsersRoles.SuperUser],
	},
	admin_subscriptions: {
		path: Routes.admin_subscriptions,
		access: [EUsersRoles.SuperAdmin, EUsersRoles.SuperUser],
	},
	// Routes for all
	profile_settings: {
		path: Routes.profile_settings,
		access: [EUsersRoles.SuperAdmin, EUsersRoles.SuperUser, EUsersRoles.Admin, EUsersRoles.Responsible, EUsersRoles.BackOffice],
	},
	not_access: {
		path: Routes.not_access,
		access: [EUsersRoles.Operator],
	},
};

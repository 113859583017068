import { createTheme } from '@mui/material';

export const ColorPalette = {
	black: '#000000',
	transparentBlack: '#2D2D2D',
	transparentGray: '#2d2d2d1c',
	darkRed: '#CB0D00',
	red: '#FE0303',
	lightRed: '#EE8780',
	orange: '#FF8800',
	lightOrange: '#FFAF3E',
	yellow: '#FFD254',
	amber: '#FFBD00',
	beige: '#FFCD87',
	purple: '#A4AFFF',
	green: '#009537',
	lightGreen: '#90D9AB',
	turquoise: '#91E6DF',
	lilac: '#D791E6',
	violet: '#9c30fd',
	pink: '#FFBFA4',
	white: '#FFFFFF',
	gray: '#5E5E5E',
	semiLightGray: '#DFDFDF',
	lightGray: '#EFEFEF',
} as const;

const spacingFunction = (num: number) => `${4 * num}px`;
export const theme = createTheme({
	spacing: spacingFunction,
	palette: {
		primary: {
			main: ColorPalette.black,
		},
		secondary: {
			main: ColorPalette.lightGray,
		},
		error: {
			main: ColorPalette.red,
		},
		success: {
			main: ColorPalette.green,
		},
		warning: {
			main: ColorPalette.yellow,
		},
	},
	typography: {
		fontFamily: 'Raleway',
		h2: {
			fontSize: '60px',
			lineHeight: '46px',
			fontWeight: 700,
		},
		h3: {
			fontSize: '48px',
			lineHeight: '46px',
			fontWeight: 700,
		},
		h4: {
			fontSize: '34px',
			lineHeight: '38px',
			fontWeight: 700,
			letterSpacing: '0.25px',
		},
		h5: {
			fontSize: '26px',
			lineHeight: '27px',
			fontWeight: 700,
		},
		h6: {
			fontSize: '20px',
			lineHeight: '22px',
			fontWeight: 700,
			letterSpacing: '0.15px',
		},
		subtitle1: {
			fontSize: '18px',
			lineHeight: '20px',
			fontWeight: 700,
			letterSpacing: '0.15px',
		},
		body1: {
			fontSize: '16px',
			lineHeight: '19px',
			fontWeight: 700,
			letterSpacing: '0.5px',
		},
		body2: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 500,
			letterSpacing: '0.25px',
		},
		button: {
			fontSize: '14px',
			fontWeight: 700,
			letterSpacing: '0.5px',
			textTransform: 'inherit',
		},
		caption: {
			fontSize: '12px',
			lineHeight: '16px',
			fontWeight: 700,
			letterSpacing: '0.4px',
		},
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiInputBase-root': {
						'fontSize': '12px',
						'color': ColorPalette.black,
						'&:placeholder': {
							color: ColorPalette.lightGray,
						},
					},
					'& .Mui-disabled': {
						overflow: 'hidden',
						backgroundColor: ColorPalette.lightGray,
					},
					'& .MuiInputBase-input': {
						padding: spacingFunction(3),
					},
					'& .MuiSelect-select': {
						padding: spacingFunction(3),
					},
					'& .MuiInputBase-multiline': {
						padding: 0,
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					'& .Mui-disabled': {
						overflow: 'hidden',
						backgroundColor: ColorPalette.lightGray,
					},
					'& .MuiSelect-select': {
						fontSize: '12px',
						padding: spacingFunction(2.3),
					},
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					'& .MuiAutocomplete-inputRoot': {
						padding: spacingFunction(1.1),
					},
				},
				option: {
					fontSize: '12px',
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: ColorPalette.lightOrange,
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						color: ColorPalette.lightOrange,
					},
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: ColorPalette.semiLightGray,
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					'color': ColorPalette.gray,
					'&:hover svg': {
						color: ColorPalette.gray,
					},
				},
			},
		},
	},
});

import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog as MuiDialog, DialogContent, DialogProps, DialogTitle, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorPalette, theme } from 'shared/theme';

import { CustomDialogType } from './type-helpers';

type DialogPropsType = DialogProps &
	CustomDialogType<unknown> & {
		title: string;
		children: React.ReactNode;
	};

const Dialog: FC<DialogPropsType> = ({ title, open, handleClose, children }) => {
	return (
		<StyledDialog onClose={handleClose} open={open}>
			<Box sx={{ backgroundColor: ColorPalette.lightOrange }}>
				<DialogTitle variant="h6">{title}</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: ColorPalette.black,
					}}
				>
					<CloseIcon />
				</IconButton>
			</Box>
			<DialogContent>{children}</DialogContent>
		</StyledDialog>
	);
};

export default Dialog;

const StyledDialog = styled(MuiDialog)({
	'& .MuiPaper-root': {
		maxWidth: '900px',
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(4),
	},
});
